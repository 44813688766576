import qs from "qs";

import { ECluster } from "./api.types";

export interface IAppUrls {
  base: string;
  api: string;
}

const LOCALHOST_PORT = 1337;

export const getBaseUrl = (): IAppUrls => {
  const workingEnvironment =
    process.env.NEXT_PUBLIC_NODE_ENV || process.env.NODE_ENV;
  if (!workingEnvironment) {
    throw new Error(
      `Please set an environment variable called 'ENV' to one of the following values: "test", "development", "production"`
    );
  }

  let url;
  switch (workingEnvironment) {
    case "test":
      url = `http://${process.env.NEXT_PUBLIC_LOCAL_STRAPI_DOMAIN}:${LOCALHOST_PORT}`;
      break;
    case "development":
      url = `https://${process.env.NEXT_PUBLIC_DEV_STRAPI_DOMAIN}`;
      break;
    case "production":
      url = `https://${process.env.NEXT_PUBLIC_PROD_STRAPI_DOMAIN}`;
      break;
  }

  if (!url)
    throw new Error(
      `There was no value for environment ${workingEnvironment} found in your .env file.`
    );
  return {
    base: url,
    api: `${url}/api`,
  };
};

export const APP_URLS = getBaseUrl();

export async function fetchITClusterPage() {
  try {
    const response = await fetch(`${APP_URLS.api}/it-page`);
    if (!response.ok)
      return {
        errorCode: response.status,
        errorText: response.statusText,
      };
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { errorCode: 500, errorText: error };
  }
}

export async function fetchPageBySlug(slug: string, locale: string) {
  try {
    const response = await fetch(
      `${APP_URLS.api}/pages/${slug}?locale=${locale}`
    );
    if (!response.ok)
      return {
        errorCode: response.status,
        errorText: response.statusText,
      };
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { errorCode: 500, errorText: error };
  }
}

export async function fetchAllPages(locale = "all") {
  try {
    const response = await fetch(`${APP_URLS.api}/pages?locale=${locale}`);
    if (!response.ok)
      return {
        errorCode: response.status,
        errorText: response.statusText,
      };
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { errorCode: 500, errorText: error };
  }
}

export async function fetchPositionsWithCluster(
  locale = "all",
  start: number,
  limit?: number,
  cluster?: ECluster
) {
  try {
    let filters: any = {};
    if (cluster) {
      filters.cluster = {
        $eq: cluster,
      };
    }
    const params: any = {
      filters,
      locale: [locale],
      start,
    };

    if (limit) params.limit = limit;

    const query = qs.stringify(params, {
      encodeValuesOnly: true,
    });

    const response = await fetch(`${APP_URLS.api}/jobs?${query}`);
    if (!response.ok)
      return {
        errorCode: response.status,
        errorText: response.statusText,
      };
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { errorCode: 500, errorText: error };
  }
}

export async function fetchPositions(query?: string, page: number = 1) {
  try {
    const response = await fetch(
      `${
        APP_URLS.api
      }/jobs?populate=*&${query}&pagination[page]=${page}&pagination[pageSize]=${12}`
    );
    if (!response.ok)
      return {
        errorCode: response.status,
        errorText: response.statusText,
      };
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { errorCode: 500, errorText: error };
  }
}

export type AvailableInstagramFields =
  | "id"
  | "caption"
  | "media_type"
  | "media_url"
  | "timestamp"
  | "permalink"
  | "thumbnail_url";

const DEFAULT_INSTAGRAM_FIELDS: AvailableInstagramFields[] = [
  "id",
  "caption",
  "media_type",
  "media_url",
  "timestamp",
  "thumbnail_url",
  "permalink",
];
export async function fetchInstagramPosts(
  fields: AvailableInstagramFields[] = DEFAULT_INSTAGRAM_FIELDS
) {
  try {
    const parsedFields = fields.join(",");
    const response = await fetch(
      `https://graph.instagram.com/me/media?fields=${parsedFields}&access_token=${process.env.NEXT_PUBLIC_INSTAGRAM_ACCESS_TOKEN}`
    );

    if (!response.ok) throw new Error(response.statusText);
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error(
      `fetchInstagramPosts function failed with the following error: ${error}`
    );
  }
}

export async function fetchPositionByJobTitle(
  locale = "all",
  jobTitle: string
) {
  try {
    const slug = jobTitle
      ? jobTitle.replace(/\s/g, "-")?.toLowerCase()
      : jobTitle;
    const query = qs.stringify(
      {
        filters: {
          slug: {
            $eq: slug,
          },
        },
        locale: [locale],
      },
      {
        encodeValuesOnly: true,
      }
    );
    const response = await fetch(`${APP_URLS.api}/jobs?${query}`);
    if (!response.ok)
      return {
        errorCode: response.status,
        errorText: response.statusText,
      };
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { errorCode: 500, errorText: error };
  }
}

export async function fetchPositionBySlug(locale = "all", slug: string) {
  try {
    const query = qs.stringify(
      {
        filters: {
          slug: {
            $eq: slug,
          },
        },
        locale: [locale],
      },
      {
        encodeValuesOnly: true,
      }
    );

    const response = await fetch(`${APP_URLS.api}/jobs?${query}`);
    if (!response.ok)
      return {
        errorCode: response.status,
        errorText: response.statusText,
      };
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    return { errorCode: 500, errorText: error };
  }
}

export async function submitForm(formName: string, formData: {}) {
  return await postJSON(
    "ezforms/submit",
    JSON.stringify({
      formName: formName,
      formData: formData,
    })
  );
}

export async function uploadFile(formData: FormData) {
  return await post("upload", formData);
}

async function post(path: string, body: FormData) {
  const url = `${APP_URLS.api}/${path}`;
  const res = await fetch(url, {
    method: "POST",
    body: body,
  });

  return res;
}

async function postJSON(path: string, body: string) {
  try {
    const url = `${APP_URLS.api}/${path}`;
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    });
    return response;
  } catch (e) {
    throw new Error(`postJSON function failed with the following error: ${e}`);
  }
}
